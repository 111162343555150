<template>
  <Modal @close="closeModal">
    <template v-slot:modal-title> {{ $t("user.profile") }} </template>
    <template v-slot:modal-body>
      <div class="field">
        <label class="label">{{ $t("user.email") }}</label>
        <div class="control">
          <input class="input" type="text" v-model="email" readonly />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t("user.fullName") }}</label>
        <div class="control">
          <input class="input" type="text" v-model="fullName" required />
        </div>
        <p
          class="help is-danger"
          v-if="fullName == '' && formValidation == false"
        >
          {{ $t("camera.createFormRequirements.name") }}
        </p>
      </div>
      <div class="field">
        <label class="label">{{ $t("user.timezone") }}</label>
        <div class="control">
          <span class="select" style="width: 100%">
            <select v-model="timezone" style="width: 100%">
              <option
                v-for="timezone in timezones"
                :key="timezone"
                :value="timezone"
              >
                {{ timezone }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t("menu.language") }}</label>
        <div class="control">
          <span class="select" style="width: 100%">
            <select v-model="language" style="width: 100%">
              <option
                v-for="locale in $i18n.availableLocales"
                :key="locale"
                :value="locale"
              >
                {{ $t("user.languages." + locale) }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <button class="button is-warning" @click="isPasswordModalOpen = true">
        Change Password
      </button>
    </template>
    <template v-slot:modal-footer>
      <button class="button is-success">Save changes</button>
      <button class="button">Cancel</button>
    </template>
    <transition name="fade">
      <ChangePassword
        @close="isPasswordModalOpen = false"
        v-if="isPasswordModalOpen"
      />
    </transition>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/Modal.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import { timezones } from "@/assets/timezones.js";

export default {
  components: {
    Modal,
    ChangePassword,
  },
  data() {
    return {
      email: "",
      language: "tr",
      timezone: "Europe/Istanbul",
      fullName: "",
      formValidation: true,
      timezones: timezones,
      isPasswordModalOpen: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    test() {
      console.log("test");
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {
    this.email = this.user.email;
    this.language = this.user.language;
    this.timezone = this.user.timezone;
    this.fullName = this.user.fullName;
  },
};
</script>

<style></style>
